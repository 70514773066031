/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $(document).ready(function() {
    var body = $('.js-body');

    // Mobile Header

    var mobileCallToggle = $('.js-mast-call-toggle');
    var mobileSearchToggle = $('.js-mast-search-toggle');
    var mobileMenuToggle = $('.js-mast-menu-toggle');

    var mastCall = $('.js-mast-call');
    var mastSearch = $('.js-mast-search');
    var mastMenu = $('.js-mast-menu');

    mobileMenuToggle.click(function() {
        body.toggleClass('has-open-segue');
        mastMenu.toggleClass('is-open');
        mobileMenuToggle.toggleClass('is-active');
    });

    mobileSearchToggle.click(function() {
        mastSearch.toggleClass('is-active');
        mobileSearchToggle.toggleClass('is-active');
    });

    // Mobile Menu

    var submenuParent = $('.js-mast-menu .js-submenu-expand');

    submenuParent.append('<button class="button--menuExpand align--top-right js-submenu-expand"><i class="ion-chevron-down"></i></button>');

    submenuParent.click(function() {
        $(this).toggleClass('is-active');
    });

    // Disable Search button if empty

    var searchbar = $('.js-searchbar');
    var searchbarButton = $('.js-searchbar-button');

    searchbarButton.prop('disabled', true);
    searchbar.keyup(function(){
        searchbarButton.prop('disabled', this.value == "" ? true : false);
    });

    // Modal

    var modal = $('.js-modal');
    var modalToggle = $('.js-modal-toggle');
    var modalImgBtn = $('.js-modal-img-btn');
    var modalImgLrg = $('.js-modal-img-lrg');

    modalToggle.click(function() {
        modal.toggleClass('is-open');
        body.toggleClass('has-open-segue');
    });

    modalImgBtn.click(function() {
        var dataSrc = modalImgLrg.attr('data-src');
        var imgSrc = $(this).attr('img-src');
        modalImgLrg.attr('src', dataSrc+imgSrc+'.jpg');
    });

    // var time = 4;
    // var $bar,
    //     $slick,
    //     isPause,
    //     tick,
    //     percentTime;
    //
    // $bar = $('.header-progress .progress-bar');

    // $('#header .slick-track').on({
    //     mouseenter: function() {
    //         isPause = true;
    //     },
    //     mouseleave: function() {
    //         isPause = false;
    //     }
    // })

    // function startProgressbar() {
    //     resetProgressbar();
    //     percentTime = 0;
    //     isPause = false;
    //     tick = setInterval(interval, 10);
    // }
    //
    // function interval() {
    //
    //         percentTime += 1 / (time+0.1);
    //         $bar.css({
    //             width: percentTime+"%"
    //         });
    //         if(percentTime >= 100) {
    //             $('.js-header').slick('slickNext');
    //             startProgressbar();
    //         }
    //
    // }
    //
    // function resetProgressbar() {
    //     $bar.css({
    //         width: 0+'%'
    //     });
    //     clearTimeout(tick);
    // }
    //
    // startProgressbar();

    // Homepage Featured Products

    var featProdSlide = $('.js-featuredProduct-slide');
    var featProdLink = $('.js-featuredProduct-link');
    var featProdBtn = $('.js-featuredProduct-link > button');
    var featProdLinkActive = $('.js-featuredProduct-link[data-slide=0]');

    $('.js-featuredProduct-slide[data-slide=0]').removeClass('is-hidden');
    featProdLinkActive.addClass('is-active');

    featProdBtn.click(function() {
        var dataLinkAttr = $(this).closest('li').attr('data-slide');
        var featProdSlideActive = $('.js-featuredProduct-slide[data-slide=' + dataLinkAttr + ']');
        var featProdLinkActive = $('.js-featuredProduct-link[data-slide=' + dataLinkAttr + ']');

        featProdLink.removeClass('is-active');
        featProdLinkActive.addClass('is-active');

        featProdSlide.addClass('is-hidden');
        featProdSlideActive.removeClass('is-hidden');
    });

    // Homepage Testimonials

    $('.js-testimonials').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        dots: true,
        arrows: false,
        dotsClass: 'list--unstyle list--inline list--carouselCntrls',
        appendDots: $('.js-testimonials-dots'),
    });

    // Contact form 7 form validation
    // Trying to add classes to elements when focus - not working

    // var form = $('.wpcf7-form');
    // var formInvalid = $('.wpcf7-not-valid');
    // var formInvalidTip = $('.wpcf7-not-valid-tip');
    //
    // form.find('.wpcf7-submit').prop('disabled', true);
    //
    // formInvalid.focus(function() {
    //     find.formInvalidTip.addClass('is-hidden');
    //     console.log('well fuck');
    // })
    // if(formChecks.val().length < 0) {
    //     form.find('.wpcf7-submit').prop('disabled', true);
    // } else {
    //     form.find('.wpcf7-submit').prop('disabled', false);
    // }
  });

})(jQuery); // Fully reference jQuery after this point.
